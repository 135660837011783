import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../css/cssScreens/Productos.css";
import axios from "axios";
import MenuProducts from "../components/productosComponents/MenuProducts";
import ShowDescProduct from "../components/productosComponents/ShowDescProduct";
import { dataItems } from "../data/vrv_submenu";

function VRV() {
    const [products, setProducts] = useState({});
    const [product, setProduct] = useState({});

    const fetchData = useCallback(() => {
        axios
            .get("/api/vrv.json")
            .then((response) => {
                setProducts(response.data["products"]);
                setProduct(response.data["products"][0]);
                /* console.log(product) */
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleScrollToProductImg = () => {
        const productImg = document.getElementById("productImg");
        const yOffset = -140;
        const y =
            productImg.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
    };

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <img
                        src="/daikin-content/vrv/vrv6_header.jpg"
                        alt="VRV header"
                        className="headerImage"
                    />
                </Col>
            </Row>

            <Container>
                <Row>
                    <Col xs={12}>
                        <span className="headerText">
                            Tecnologia <strong>VRV</strong>
                        </span>
                    </Col>
                </Row>
                <Row className="py-5">
                    <Col xs={12} md={7} className="mb-3">
                        <div className="leftBorder py-4 px-2">
                            <p className="">
                                Con nuestra tecnología VRV®, podemos
                                personalizar cada zona de manera individual, ya
                                sea por cuarto o por piso, dando como resultado
                                una mayor eficiencia y un consumo mínimo de
                                energía eléctrica.
                            </p>

                            <p>
                                Estos sistemas están enfocados para uso
                                comercial, como pueden ser: oficinas, colegios,
                                hoteles, hospitales, residencias, etc. Este
                                resultado se logra gracias a su gran
                                adaptabilidad de diseño.
                            </p>

                            <p>
                                Los productos Daikin son reconocidos por su gran
                                calidad y confiabilidad. Ofrecemos una amplia
                                variedad de productos de última generación,
                                diseñados y fabricados con tecnologías propias,
                                que brindan la flexibilidad de adaptarse a los
                                requerimientos de su aplicación.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={5}>
                        <img
                            src="/daikin-content/vrv/vrv_home.jpg"
                            alt="VRV inside a home"
                            className="headerImage"
                        />
                    </Col>
                </Row>
                <Row className="my-5 pt-3 pb-5">
                    <Col
                        xs={12}
                        lg={3}
                        className="containerProducts mb-5 mb-md-0"
                    >
                        <MenuProducts
                            dataItems={dataItems}
                            setProduct={(e) => {
                                setProduct(e);
                            }}
                            handleScrollToProductImg={() =>
                                handleScrollToProductImg()
                            }
                            products={products}
                        />
                    </Col>

                    {Object.keys(product).length > 0 &&
                    product.hasOwnProperty("image") &&
                    product.hasOwnProperty("content") ? (
                        <>
                            <Col
                                id="productImg"
                                xs={12}
                                lg={5}
                                className="d-flex align-items-center justify-content-center mb-5 mb-md-0"
                            >
                                {product.image ? (
                                    <Image
                                        fluid
                                        src={require(`../img/Productos/VRV/Productos/${product["image"]}`)}
                                    />
                                ) : (
                                    <Image
                                        fluid
                                        src={require(`../img/Productos/VRV/Productos/6.-CASSETTE-MONTADO-EN-TECHO.png`)}
                                    />
                                )}
                            </Col>
                            <ShowDescProduct product={product} />
                        </>
                    ) : null}
                </Row>
            </Container>
        </Container>
    );
}

export default VRV;
