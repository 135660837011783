export const dataItems = [
    {
        item: "Enfriados por agua",
        data: [
            {
                id: 0,
                nameProduct: "VRV W Heat Recovery Frío y calor simultáneos",
            },
            {
                id: 1,
                nameProduct: "VRV W Heat Pump Sistemas inteligentes",
            },
        ],
    },
    {
        item: "Enfriados por aire",
        data: [
            {
                id: 2,
                nameProduct: "VRV IVS – Solución para espacios pequeños",
            },
            {
                id: 3,
                nameProduct: "Heat Pump – Alta eficiencia",
            },
            {
                id: 4,
                nameProduct: "Heat Recovery – Frio y calor simultáneos",
            },
        ],
    },
    {
        item: "Nuevos controles y accesorios",
        data: [
            {
                id: 5,
                nameProduct: "Daikin Zoning Kit (DZK)",
            },
            {
                id: 6,
                nameProduct: "Ventilador recuperador de calor (VAM)",
            },
            {
                id: 7,
                nameProduct: "Control de navegación",
            },
            {
                id: 8,
                nameProduct: "Smart VRV Manager (SVM)",
            },
            {
                id: 9,
                nameProduct: "Intelligent Touch Manager (iTM)",
            },
        ],
    },
    {
        item: "Unidades interiores",
        data: [
            {
                id: 10,
                nameProduct: "Unidades Manejadoras de Aire (AHU)",
            },
            {
                id: 11,
                nameProduct: "Montado en pared",
            },
            {
                id: 12,
                nameProduct: "Ductable oculto sobre plafón",
            },
            {
                id: 13,
                nameProduct: "Cassette de 4 vías suspendido en techo",
            },
            {
                id: 14,
                nameProduct:
                    "Cassette montado en techo (flujo circular con sensor)",
            },
        ],
    },
    {
        item: "Nuevo VRV 6",
        data: [
            {
                id: 15,
                nameProduct: "VRV 6 Heat Pump",
            },
        ],
    },
];
