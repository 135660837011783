import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Update this import
import { default as React, useState } from "react";

import "../css/cssScreens/CulturaDaikin.css";

function Formulario() {
    const [showSection, setShowSection] = useState(false);
    const [modelo, setModelo] = useState("");
    const [numeroSerie, setNumeroSerie] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [fechaCompra, setFechaCompra] = useState("");

    const navigate = useNavigate(); // Update this variable

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/formulario-archivos"); // Update this line
    };

    const checkAndFillEmpresa = (modelo, numeroSerie) => {
        console.log(fechaCompra);
        if (modelo === "MQS-4060-CCK216B" && numeroSerie === "9W96040000097") {
            setEmpresa("SIERRA MADRE NORTE");
            setFechaCompra("2024-07-19");
        }
    };

    const searchModel = () => {
        checkAndFillEmpresa(modelo, numeroSerie);
        setShowSection(!showSection);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom style={{ marginTop: "20px" }}>
                Solicitud de garantía
            </Typography>
            <p>
                Favor de completar este documento con la información que se
                solicita.
                <br />
                La factura de compra es muy importante para poder procesar su
                garantía por lo que no podrá enviar está solicitud si no la
                adjunta (la factura debe contar incluir el modelo y numero de
                serie) Es conveniente anexar evidencias fotográficas del defecto
                o falla que está reportando, cuanto más completa es la
                información más rápida será la atención de su garantía.
            </p>
            <form
                noValidate
                autoComplete="off"
                style={{ marginBottom: "20px" }}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Modelo"
                            type="text"
                            value={modelo}
                            onChange={(e) => setModelo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Número de serie"
                            type="text"
                            value={numeroSerie}
                            onChange={(e) => setNumeroSerie(e.target.value)}
                        />
                    </Grid>
                    {!showSection && (
                        <>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={searchModel}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        </>
                    )}
                    {showSection && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre del solicitante"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Empresa"
                                    type="text"
                                    value={empresa}
                                    onChange={(e) => setEmpresa(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Fecha de compra"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={fechaCompra}
                                    onChange={(e) =>
                                        setFechaCompra(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Número celular"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Empresa donde está instalado el equipo"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Dirección donde está instalado el equipo"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre del contacto en sitio"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Número de celular del contacto en sitio"
                                    type="text"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Describa la falla que está presentando el equipo"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Continuar
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </form>
        </Container>
    );
}

export default Formulario;
