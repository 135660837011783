import {
    Button,
    Container,
    TextField,
    Typography,
    LinearProgress,
    Grid,
    IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { default as React, useState } from "react";
import "../css/cssScreens/CulturaDaikin.css";

function Formulario() {
    const [fileInputs, setFileInputs] = useState([
        { id: 1, showProgress: false, progress: 0 },
    ]);

    const addFileInput = () => {
        setFileInputs([
            ...fileInputs,
            { id: fileInputs.length + 1, showProgress: false, progress: 0 },
        ]);
    };

    const removeFileInput = (id) => {
        setFileInputs(fileInputs.filter((input) => input.id !== id));
    };

    const handleFileChange = (id) => {
        const updatedFileInputs = fileInputs.map((input) => {
            if (input.id === id) {
                return { ...input, showProgress: true, progress: 0 };
            }
            return input;
        });
        setFileInputs(updatedFileInputs);

        const randomTime = Math.floor(Math.random() * 8000) + 3000; // Random time between 3 to 10 seconds
        const interval = 100; // Update every 100ms
        const increment = 100 / (randomTime / interval);

        const intervalId = setInterval(() => {
            setFileInputs((prevFileInputs) =>
                prevFileInputs.map((input) => {
                    if (input.id === id) {
                        const newProgress = input.progress + increment;
                        if (newProgress >= 100) {
                            clearInterval(intervalId);
                            return { ...input, progress: 100 };
                        }
                        return { ...input, progress: newProgress };
                    }
                    return input;
                })
            );
        }, interval);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom style={{ marginTop: "20px" }}>
                Adjuntar documentos (Paso 2/2)
            </Typography>
            {fileInputs.map((input, index) => (
                <div key={input.id} style={{ marginBottom: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            type="file"
                            required
                            fullWidth
                            margin="normal"
                            onChange={() => handleFileChange(input.id)}
                        />
                        {index !== 0 && (
                            <IconButton
                                onClick={() => removeFileInput(input.id)}
                                aria-label="delete"
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </div>
                    {input.showProgress && (
                        <LinearProgress
                            variant="determinate"
                            value={input.progress}
                        />
                    )}
                </div>
            ))}
            <Button variant="contained" color="primary" onClick={addFileInput}>
                Agregar otro archivo
            </Button>

            <Grid
                item
                xs={12}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                <Button variant="contained" color="primary" type="submit">
                    Enviar
                </Button>
            </Grid>
        </Container>
    );
}

export default Formulario;
